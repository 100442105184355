import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ExpandableVolumeSnippet from './expandable-volume';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h4 {...{
      "id": "cluster-requirements"
    }}>{`Cluster requirements`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Kubernetes version >=1.23 <= 1.25`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kubernetes nodes:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ensure you have enough resources available (we suggest a total `}<strong parentName="li">{`minimum`}</strong>{` of 4 vcpu & 8GB of memory)`}</li>
          <li parentName="ul">{`ensure you can run `}<inlineCode parentName="li">{`x86-64`}</inlineCode>{`/`}<inlineCode parentName="li">{`amd64`}</inlineCode>{` workloads. `}<inlineCode parentName="li">{`arm64`}</inlineCode>{` architecture is currently not supported`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Suggestion: ensure `}<inlineCode parentName="p">{`allowVolumeExpansion`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`True`}</inlineCode>{` in the storage class definition (this setting enables `}<inlineCode parentName="p">{`PVC`}</inlineCode>{` resize)`}</p>
        <ExpandableVolumeSnippet mdxType="ExpandableVolumeSnippet" />
      </li>
      <li parentName="ul">
        <p parentName="li">{`Suggestion: ensure `}<inlineCode parentName="p">{`reclaimPolicy`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`Retain`}</inlineCode>{` in the storage class definition (this setting allows for manual reclamation of the resource)`}</p>
  <details>
          <p parentName="li">{`  The `}<inlineCode parentName="p">{`Retain`}</inlineCode>{` reclaim policy allows for manual reclamation of the resource. When the PersistentVolumeClaim is deleted, the PersistentVolume still exists and the volume is considered "released".
But it is not yet available for another claim because the previous claimant's data remains on the volume (see the `}<a parentName="p" {...{
              "href": "https://kubernetes.io/docs/concepts/storage/persistent-volumes/#reclaiming"
            }}>{`official documentation`}</a>{`).`}</p>
          <p parentName="li">{`  This can become useful in case your need to reprovision a pod/statefulset but you don't want to lose the underlying data`}</p>
          <p parentName="li">{`  To verify which `}<inlineCode parentName="p">{`reclaimPolicy`}</inlineCode>{` your default storage class is using you can run:`}</p>
          <pre parentName="li"><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`kubectl get storageclass -o json | jq '.items[].reclaimPolicy'
"Retain"
`}</code></pre>
          <p parentName="li">{`  If your storage class allows it, you can modify the `}<inlineCode parentName="p">{`reclaimPolicy`}</inlineCode>{` by running:`}</p>
          <pre parentName="li"><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`DEFAULT_STORAGE_CLASS=$(kubectl get storageclass -o=jsonpath='{.items[?(@.metadata.annotations.storageclass\\.kubernetes\\.io/is-default-class=="true")].metadata.name}')
kubectl patch storageclass "$DEFAULT_STORAGE_CLASS" -p '{"reclaimPolicy": "Retain"}'
storageclass.storage.k8s.io/gp2 patched
`}</code></pre>
  </details>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      