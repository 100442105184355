import ReactNativeInstall from "../../../../contents/docs/integrate/client/react-native/snippets/install.mdx";
import ReactNativeConfigure from "../../../../contents/docs/integrate/client/react-native/snippets/configure.mdx";
import ReactNativeIdentify from "../../../../contents/docs/integrate/client/react-native/snippets/identify.mdx";
import ReactNativeCapture from "../../../../contents/docs/integrate/client/react-native/snippets/capture.mdx";
import * as React from 'react';
export default {
  ReactNativeInstall,
  ReactNativeConfigure,
  ReactNativeIdentify,
  ReactNativeCapture,
  React
};