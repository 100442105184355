import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To uninstall the chart with the release name `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` in `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` namespace, you can run: `}<inlineCode parentName="p">{`helm uninstall posthog --namespace posthog`}</inlineCode>{` (take a look at the `}<a parentName="p" {...{
        "href": "https://helm.sh/docs/helm/helm_uninstall/"
      }}>{`Helm docs`}</a>{` for more info about the command).`}</p>
    <p>{`The command above removes all the Kubernetes components associated with the chart and deletes the release.
Sometimes everything doesn't get properly removed. If that happens try deleting the namespace: `}<inlineCode parentName="p">{`kubectl delete namespace posthog`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      