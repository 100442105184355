import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Check = makeShortcode("Check");
const Close = makeShortcode("Close");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}><span className="text-red">{`PostHog`}</span></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Google Analytics`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Free version available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Check mdxType="Check" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Easy to use and deploy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Self-hosted deployment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Analytics suite`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Check mdxType="Check" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Feature flags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Multivariate testing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Check mdxType="Check" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Data warehouse integrations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ad-blocker resistant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Check color="red" mdxType="Check" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><Close mdxType="Close" /></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      