import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`PostHog::capture(array(
  'distinctId' => 'user:123',
  'event' => 'movie played',
  'properties' => array(
    'movieId' => '123',
    'category' => 'romcom'
  )
));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      