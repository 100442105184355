import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To install the chart using `}<a parentName="p" {...{
        "href": "https://helm.sh/"
      }}>{`Helm`}</a>{` with the release name `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` in the `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` namespace, run the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`helm repo add posthog https://posthog.github.io/charts-clickhouse/
helm repo update
helm upgrade --install -f values.yaml --timeout 30m --create-namespace --namespace posthog posthog posthog/posthog --wait --wait-for-jobs --debug
`}</code></pre>
    <p>{`Note: if you decide to use a different Helm release name or namespace, please keep in mind you might have to change several values in
your `}<inlineCode parentName="p">{`values.yaml`}</inlineCode>{` in order to make the installation successful. This is because we build several Kubernetes resources
(like service names) using those.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      