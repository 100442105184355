import Cohorts2 from "../../../../contents/images/blog/array/1-37-0-cohorts-2-0.png";
import EventPage from "../../../../contents/images/blog/array/1-37-0-event-page.png";
import DancingHog from "../../../../contents/images/blog/array/1-37-0-dancing-hog.gif";
import ConfigurableColumns from "../../../../contents/images/blog/array/1-37-0-configurable-columns.png";
import APICompression from "../../../../contents/images/blog/array/1-37-0-compression.png";
import * as React from 'react';
export default {
  Cohorts2,
  EventPage,
  DancingHog,
  ConfigurableColumns,
  APICompression,
  React
};