import JSInstall from "../../../../contents/docs/integrate/client/js/snippets/install.mdx";
import AndroidInstall from "../../../../contents/docs/integrate/client/android/snippets/install.mdx";
import AndroidConfigure from "../../../../contents/docs/integrate/client/android/snippets/configure.mdx";
import IOSInstall from "../../../../contents/docs/integrate/client/ios/snippets/install.mdx";
import IOSConfigure from "../../../../contents/docs/integrate/client/ios/snippets/configure.mdx";
import FlutterPackage from "../../../../contents/docs/integrate/client/flutter/snippets/package.mdx";
import FlutterInstall from "../../../../contents/docs/integrate/client/flutter/snippets/install.mdx";
import ReactNativeInstall from "../../../../contents/docs/integrate/client/react-native/snippets/install.mdx";
import ReactNativeConfigure from "../../../../contents/docs/integrate/client/react-native/snippets/configure.mdx";
import Tab from "components/Tab";
import JSAutoCapture from "../../../../contents/docs/integrate/client/js/snippets/autocapture.mdx";
import JSCaptureIgnoreElements from "../../../../contents/docs/integrate/client/js/snippets/capture-ignore-elements.mdx";
import JSIdentify from "../../../../contents/docs/integrate/client/js/snippets/identify.mdx";
import AndroidIdentify from "../../../../contents/docs/integrate/client/android/snippets/identify.mdx";
import IOSIdentify from "../../../../contents/docs/integrate/client/ios/snippets/identify.mdx";
import ReactNativeIdentify from "../../../../contents/docs/integrate/client/react-native/snippets/identify.mdx";
import * as React from 'react';
export default {
  JSInstall,
  AndroidInstall,
  AndroidConfigure,
  IOSInstall,
  IOSConfigure,
  FlutterPackage,
  FlutterInstall,
  ReactNativeInstall,
  ReactNativeConfigure,
  Tab,
  JSAutoCapture,
  JSCaptureIgnoreElements,
  JSIdentify,
  AndroidIdentify,
  IOSIdentify,
  ReactNativeIdentify,
  React
};