import NodeCapture from "../../../../contents/docs/integrate/server/node/snippets/capture.mdx";
import PythonCapture from "../../../../contents/docs/integrate/server/python/snippets/capture.mdx";
import PHPCapture from "../../../../contents/docs/integrate/server/php/snippets/capture.mdx";
import GoCapture from "../../../../contents/docs/integrate/server/go/snippets/capture.mdx";
import RubyCapture from "../../../../contents/docs/integrate/server/ruby/snippets/capture.mdx";
import CURLCapture from "../../../../contents/docs/integrate/server/curl/snippets/capture.mdx";
import Tab from "components/Tab";
import NodeIdentify from "../../../../contents/docs/integrate/server/node/snippets/identify.mdx";
import PythonIdentify from "../../../../contents/docs/integrate/server/python/snippets/identify.mdx";
import PHPIdentify from "../../../../contents/docs/integrate/server/php/snippets/identify.mdx";
import GoIdentify from "../../../../contents/docs/integrate/server/go/snippets/identify.mdx";
import RubyIdentify from "../../../../contents/docs/integrate/server/ruby/snippets/identify.mdx";
import CURLIdentify from "../../../../contents/docs/integrate/server/curl/snippets/identify.mdx";
import * as React from 'react';
export default {
  NodeCapture,
  PythonCapture,
  PHPCapture,
  GoCapture,
  RubyCapture,
  CURLCapture,
  Tab,
  NodeIdentify,
  PythonIdentify,
  PHPIdentify,
  GoIdentify,
  RubyIdentify,
  CURLIdentify,
  React
};