import AndroidInstall from "../../../../contents/docs/integrate/client/android/snippets/install.mdx";
import AndroidConfigure from "../../../../contents/docs/integrate/client/android/snippets/configure.mdx";
import AndroidIdentify from "../../../../contents/docs/integrate/client/android/snippets/identify.mdx";
import AndroidCapture from "../../../../contents/docs/integrate/client/android/snippets/capture.mdx";
import * as React from 'react';
export default {
  AndroidInstall,
  AndroidConfigure,
  AndroidIdentify,
  AndroidCapture,
  React
};