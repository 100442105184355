import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The best way to install the PostHog Android library is with a build system like
Gradle. This ensures you can easily upgrade to the latest versions. `}</p>
    <p>{`All you need to do is add the `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` module to your `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dependencies {
  implementation 'com.posthog.android:posthog:1.+'
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      