import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DomainDisclaimer from './domain-disclaimer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <DomainDisclaimer mdxType="DomainDisclaimer" />
    <p>{`Create the record of your desired hostname pointing to the address found above.
After around 30 minutes (required to request, receive and deploy the TLS certificate) you should
have a fully working and secure PostHog instance available at the domain record you've chosen!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      