import Features from "../../../../src/components/Home/Features";
import Customers from "../../../../src/components/Home/Customers";
import Community from "../../../../src/components/Home/Community";
import Pipelines from "../../../../src/components/Home/Pipelines";
import Tutorials from "../../../../src/components/Home/Tutorials";
import CTA from "../../../../src/components/Home/CTA";
import { FeatureStrip } from "../../../../src/components/Home/Hero";
import * as React from 'react';
export default {
  Features,
  Customers,
  Community,
  Pipelines,
  Tutorials,
  CTA,
  FeatureStrip,
  React
};