import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Self-host vs PostHog Cloud:`}</strong><br />{`
Self-hosting PostHog means managing the service yourself, taking care of upgrades, scaling, security etc.`}<br /><br />{`
If you are less technical or looking for a hands-off experience,
and if you want to optimize your budget, we recommend
`}<a parentName="p" {...{
          "href": "/docs/getting-started/cloud"
        }}>{`PostHog Cloud`}</a>{`.`}<br /><br />{`
PostHog Cloud is also considerably cheaper than paying for cloud hosting.
You can find more information about PostHog Cloud's costs on the `}<a parentName="p" {...{
          "href": "/pricing"
        }}>{`pricing page`}</a>{`.`}<br /><br />{`
You can also find support partners to
manage the service for you via `}<a parentName="p" {...{
          "href": "/marketplace"
        }}>{`the PostHog Marketplace`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      