import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When you start tracking events with PostHog, each user gets an anonymous ID that is used to identify them in the system.
In order to link this anonymous user with someone from your database, use the `}<inlineCode parentName="p">{`identify`}</inlineCode>{` call. `}</p>
    <p>{`Identify lets you add metadata on your users so you can more easily identify who they are in PostHog, and even do things
like segment users by these properties.`}</p>
    <p>{`An identify call requires:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`distinctId`}</inlineCode>{` which uniquely identifies your user in your database`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`userProperties`}</inlineCode>{` with a dictionary with key: value pairs`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`posthog.identify('distinctID', {
    email: 'user@posthog.com',
    name: 'My Name'
})
`}</code></pre>
    <p>{`The most obvious place to make this call is whenever a user signs up, or when they update their information.`}</p>
    <p>{`When you call `}<inlineCode parentName="p">{`identify`}</inlineCode>{`, all previously tracked anonymous events will be linked to the user.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      