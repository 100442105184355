import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`PostHog puts a great amount of effort into making sure it doesn't capture any sensitive data from your website. If there are other elements you don't want to be captured, you can add the `}<inlineCode parentName="p">{`ph-no-capture`}</inlineCode>{` class name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class='ph-no-capture'>Sensitive information here</button>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      