import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Snippet from "../../../snippet.mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can either load the snippet as a script in your HTML:`}</p>
    <Snippet mdxType="Snippet" />
    <p>{`Place the snippet in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` tags of your website, ideally just above the closing `}<inlineCode parentName="p">{`</head>`}</inlineCode>{` tag. You will need to do this for all pages that you wish to track.`}</p>
    <p>{`Or you can include it using npm, by doing either:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add posthog-js
`}</code></pre>
    <p>{`or:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save posthog-js
`}</code></pre>
    <p>{`And then include it in your files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import posthog from 'posthog-js'
posthog.init('<ph_project_api_key>', { api_host: '<ph_instance_address>' })
`}</code></pre>
    <p>{`If you don't want to send a bunch of test data while you're developing, you could do the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if (!window.location.href.includes('127.0.0.1')) {
    posthog.init('<ph_project_api_key>', { api_host: '<ph_instance_address>' })
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      