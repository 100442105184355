import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Do not use `}<inlineCode parentName="strong">{`posthog`}</inlineCode>{` or tracking related words as your sub-domain record:`}</strong>{`
As we grow, PostHog owned domains might be added to tracker blockers.
To reduce the risk of tracker blockers interfering with events sent to your self-hosted instance,
we suggest to avoid using any combination of potentially triggering words as your sub-domain.
Examples of words to avoid are: `}<inlineCode parentName="p">{`posthog`}</inlineCode>{`, `}<inlineCode parentName="p">{`tracking`}</inlineCode>{`, `}<inlineCode parentName="p">{`tracker`}</inlineCode>{`, `}<inlineCode parentName="p">{`analytics`}</inlineCode>{`, `}<inlineCode parentName="p">{`metrics`}</inlineCode>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      