import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Capture allows you to send events related to anything a user does within your system, which you can later use in PostHog to find
patterns in usage, work out which features to improve, or find out where people are giving up.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`capture`}</inlineCode>{` call requires:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`event name`}</inlineCode>{` to specify the event`}
        <ul parentName="li">
          <li parentName="ul">{`We recommend using `}{`[noun][verb]`}{`, like `}<inlineCode parentName="li">{`movie played`}</inlineCode>{` or `}<inlineCode parentName="li">{`movie updated`}</inlineCode>{` to easily identify what your events mean later on.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Optionally you can submit:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`properties`}</inlineCode>{`, which can be an array with any information you'd like to add`}</li>
    </ul>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// in objective-c
[[PHGPostHog sharedPostHog] capture:@"Signed Up" properties:@{ @"plan": @"Pro++" }];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// in swift
posthog.capture("Signed Up", properties: ["plan": "Pro++"])
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      