import React from 'react'

export default function Logo({ className = '' }) {
    return (
        <svg fill="none" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 56">
            <path
                d="M19.688 33.683a1 1 0 0 1-1.787 0l-2.639-5.241a1 1 0 0 1 0-.9l2.64-5.24a1 1 0 0 1 1.786 0l2.639 5.24a1 1 0 0 1 0 .9l-2.64 5.241ZM19.688 52.344a1 1 0 0 1-1.787 0l-2.639-5.241a1 1 0 0 1 0-.9l2.64-5.24a1 1 0 0 1 1.786 0l2.639 5.24a1 1 0 0 1 0 .9l-2.64 5.241Z"
                fill="#1D4AFF"
            />
            <path
                d="M0 41.591c0-.89 1.074-1.336 1.705-.71l11.609 11.527c.633.629.188 1.71-.705 1.71H1a1 1 0 0 1-1-1V41.59Zm0-6.55a1 1 0 0 0 .295.709l18.207 18.077a1 1 0 0 0 .704.29h12.197c.893 0 1.338-1.08.705-1.709L1.705 22.22C1.074 21.594 0 22.04 0 22.93v12.11Zm0-18.662a1 1 0 0 0 .295.71l37.001 36.738a1 1 0 0 0 .705.29h12.197c.892 0 1.338-1.08.704-1.709L1.705 3.56C1.074 2.933 0 3.38 0 4.27v12.11Zm18.794 0a1 1 0 0 0 .296.71l35.589 35.336c.63.627 1.704.18 1.704-.71v-12.11a1 1 0 0 0-.295-.71L20.498 3.56c-.63-.626-1.704-.18-1.704.71v12.11Zm20.5-12.82c-.632-.626-1.705-.18-1.705.71v12.11a1 1 0 0 0 .295.71l16.794 16.675c.632.627 1.705.18 1.705-.71v-12.11a1 1 0 0 0-.295-.71L39.293 3.56Z"
                fill="#F9BD2B"
            />
            <path
                d="m79.947 43.924-19.2-19.064c-.632-.627-1.706-.18-1.706.71v27.548a1 1 0 0 0 1 1h29.172a1 1 0 0 0 1-1v-3.972c0-.552-.448-.996-1-1.034a14.565 14.565 0 0 1-9.266-4.188Zm-11.884 4.222c-1.66 0-3.007-1.337-3.007-2.986 0-1.648 1.347-2.985 3.007-2.985 1.66 0 3.007 1.337 3.007 2.985 0 1.649-1.347 2.986-3.007 2.986Z"
                fill="#fff"
            />
            <path
                d="M0 53.118a1 1 0 0 0 1 1h11.61c.892 0 1.337-1.081.704-1.71L1.704 40.881c-.63-.626-1.704-.18-1.704.71v11.527ZM18.794 20.528 1.704 3.559C1.075 2.933 0 3.38 0 4.27v12.11a1 1 0 0 0 .295.71l18.5 18.368v-14.93ZM1.705 22.22C1.074 21.594 0 22.04 0 22.93v12.11a1 1 0 0 0 .295.71l18.5 18.368v-14.93L1.704 22.22Z"
                fill="#1D4AFF"
            />
            <path
                d="M37.589 20.944a1 1 0 0 0-.296-.71L20.5 3.56c-.631-.626-1.705-.18-1.705.71v12.11a1 1 0 0 0 .296.71l18.499 18.368V20.944ZM18.794 54.118h12.61c.892 0 1.338-1.081.704-1.71l-13.314-13.22v14.93ZM18.794 20.528V35.04a1 1 0 0 0 .296.71l18.499 18.368V39.605a1 1 0 0 0-.296-.71L18.794 20.529Z"
                fill="#F54E00"
            />
        </svg>
    )
}
