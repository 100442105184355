import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ExpandableVolumeSnippet from '../../self-host/deploy/snippets/expandable-volume.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3 {...{
      "id": "requirements"
    }}>{`Requirements`}</h3>
    <p>{`You need to run a Kubernetes cluster with the `}<em parentName="p">{`Volume Expansion`}</em>{` feature enabled. This feature is supported on the majority of volume types since Kubernetes version >= 1.11 (see `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/storage/storage-classes/#allow-volume-expansion"
      }}>{`docs`}</a>{`).`}</p>
    <ExpandableVolumeSnippet mdxType="ExpandableVolumeSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      