import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The recommended flow for setting up PostHog React Native is to use the `}<inlineCode parentName="p">{`PostHogProvider`}</inlineCode>{` which utilises the Context API to pass posthog around as well as enabling autocapture and ensuring that the queue is flushed at the right time:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// App.(js|ts)
import PostHog, { usePostHog } from 'posthog-react-native'
...

export function MyApp() {
    return (
        <PostHogProvider apiKey="<ph_project_api_key>" options={{
            // PostHog API host (https://app.posthog.com by default)
            host: '<ph_instance_address>',
        }}>
            <MyComponent />
        </PostHogProvider>
    )
}

// The rest of this documentation assumes you are using the \`PostHogProvider\` 
// and as such can utilise the \`usePostHog\` hook as follows:

const MyComponent = () => {
    const posthog = usePostHog()

    useEffect(() => {
        posthog.capture("MyComponent loaded", { foo: "bar" })
    }, [])
}
`}</code></pre>
    <p>{`Alternatively you can simply create a global instance of PostHog with your API Key and any options. This allows you to use PostHog outside of the `}<inlineCode parentName="p">{`PostHogProvider`}</inlineCode>{` but limits the functionality of PostHog (such as autocapture).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// posthog.js
import PostHog from 'posthog-react-native'

export const posthog = new PostHog("<ph_project_api_key>", {
    // PostHog API host (https://app.posthog.com by default)
    host: '<ph_instance_address>',
})


// app.js
import posthog from './posthog'

posthog.identify("example@posthog.com")

// You can even use this instance with the PostHogProvider
export function MyApp() {
    return (
        <PostHogProvider client={posthog}>
            {/* Your app code */}
        </PostHogProvider>
    )
}
`}</code></pre>
    <p>{`For most people, the default configuration options will work great but if you need to you can further customise how PostHog will work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export const posthog = new PostHog("<ph_project_api_key>", {
    // PostHog API host
    host?: string = "https://app.posthog.com",
    // The number of events to queue before sending to PostHog (flushing)
    flushAt?: number = 20,
    // The interval in milliseconds between periodic flushes
    flushInterval?: number = 10000
    // If set to false, tracking will be disabled until \`optIn\` is called
    enable?: boolean = true,
    // Whether to track that \`getFeatureFlag\` was called (used by Expriements)
    sendFeatureFlagEvent?: boolean = true,
    // Whether to load feature flags when initialised or not
    preloadFeatureFlags?: boolean = true,
    // How many times we will retry HTTP requests
    fetchRetryCount?: number = 3,
    // The delay between HTTP request retries
    fetchRetryDelay?: number = 3000,
    // For Session Analysis how long before we expire a session
    sessionExpirationTimeSeconds?: number = 1800 // 30 mins
    // Whether to post events to PostHog in JSON or compressed format
    captureMode?: 'json' | 'form'
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      