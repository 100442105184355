import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -v -L --header "Content-Type: application/json" -d '{
    "api_key": "<ph_project_api_key>",
    "timestamp": "2020-08-16 09:03:11.913767",
    "context": {},
    "type": "screen",
    "distinct_id": "1234",
    "$set": {},
    "event": "$identify",
    "messageId": "123"
}' https://app.posthog.com/batch/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      