import ClusterRequirementsSnippet from "../../../../contents/docs/self-host/deploy/snippets/cluster-requirements";
import InstallingSnippet from "../../../../contents/docs/self-host/deploy/snippets/installing";
import UpgradingSnippet from "../../../../contents/docs/self-host/deploy/snippets/upgrading";
import UninstallingSnippet from "../../../../contents/docs/self-host/deploy/snippets/uninstalling";
import SetupDNS from "../../../../contents/docs/self-host/deploy/snippets/setup-dns";
import Disclaimer from "../../../../contents/docs/self-host/deploy/snippets/disclaimer";
import NextSteps from "../../../../contents/docs/self-host/deploy/snippets/next-steps";
import * as React from 'react';
export default {
  ClusterRequirementsSnippet,
  InstallingSnippet,
  UpgradingSnippet,
  UninstallingSnippet,
  SetupDNS,
  Disclaimer,
  NextSteps,
  React
};