module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 390px)","sm":"(max-width: 767px)","md":"(max-width: 1023px)","lg":"(max-width: 1279px)","xl":"(max-width: 1535px)","2xl":"(max-width: 2560px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-static-images","id":"c3fac9af-c254-5ee7-bfcd-b2b4e92b3d48","name":"gatsby-remark-static-images","version":"1.2.1","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-static-images","id":"c3fac9af-c254-5ee7-bfcd-b2b4e92b3d48","name":"gatsby-remark-static-images","version":"1.2.1","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-static-images/lib/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers","id":"b1aaa934-ac39-5fee-b9c4-125f2997a5b5","name":"gatsby-remark-autolink-headers","version":"5.23.1","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/buildhome/repo/plugins/gatsby-remark-mermaid","id":"7a3a0615-7e85-5c40-92c7-0b58aab29318","name":"gatsby-remark-mermaid","version":"2.1.0","modulePath":"/opt/buildhome/repo/plugins/gatsby-remark-mermaid/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-markdown","short_name":"starter","start_url":"/","background_color":"#f96132","theme_color":"#f96132","display":"minimal-ui","icon":"src/images/posthog-icon-white.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e5ac8d4a5b381b5caa29396fbf7c955"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"post-toc-anchor","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"sTMFPsFhdP1Ssg","apiHost":"https://app.posthog.com","head":true,"isEnabledDevMode":true,"initOptions":{"_capture_metrics":true,"persistence":"localStorage+cookie"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
