import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Create a new cluster resource that will take care of signing your TLS certificates using `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/"
      }}>{`Let’s Encrypt`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a new file called `}<inlineCode parentName="p">{`cluster-issuer.yaml`}</inlineCode>{` with the following content. Note: please remember to replace `}<inlineCode parentName="p">{`your-name@domain.com`}</inlineCode>{`
with a valid email address as you will receive email notifications on certificate renewals:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml",
            "metastring": "file=cluster-issuer.yaml",
            "file": "cluster-issuer.yaml"
          }}>{`apiVersion: cert-manager.io/v1
kind: ClusterIssuer
metadata:
  name: letsencrypt-prod
spec:
  acme:
    email: "your-name@domain.com"
    server: https://acme-v02.api.letsencrypt.org/directory
    privateKeySecretRef:
      name: posthog-tls
    solvers:
    - http01:
        ingress:
          class: nginx
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deploy this new resource to your cluster by running: `}<inlineCode parentName="p">{`kubectl apply -f cluster-issuer.yaml`}</inlineCode></p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      