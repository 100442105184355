import EventFlowDiagram from "../../../../contents/docs/runbook/services/plugin-server/diagrams/event-flow";
import PluginServerDiagram from "../../../../contents/docs/runbook/services/plugin-server/diagrams/plugin-server";
import IngestionServerDiagram from "../../../../contents/docs/runbook/services/plugin-server/diagrams/ingestion-server";
import AsyncServerDiagram from "../../../../contents/docs/runbook/services/plugin-server/diagrams/async-server";
import * as React from 'react';
export default {
  EventFlowDiagram,
  PluginServerDiagram,
  IngestionServerDiagram,
  AsyncServerDiagram,
  React
};