import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import GetInstallationAddress from './get-installation-address';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the DigitalOcean console, go to the `}<inlineCode parentName="p">{`Resources`}</inlineCode>{` tab of your Kubernetes cluster and click on the load balancer assigned to the cluster. This will bring you to the (`}<a parentName="p" {...{
            "href": "https://cloud.digitalocean.com/networking/load_balancers"
          }}>{`networking tab`}</a>{`) where you can get the IP address of the load balancer created by Kubernetes:`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/static/do-ip-location-2a8e59c9cc2ce9797e5e5fd84e3c836e.png",
            "alt": "DigitalOcean External IP location"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you have `}<a parentName="p" {...{
            "href": "https://docs.digitalocean.com/products/kubernetes/how-to/connect-to-cluster/"
          }}>{`configured `}<inlineCode parentName="a">{`kubectl`}</inlineCode>{` access to your cluster`}</a>{`, run the following commands:`}</p>
        <GetInstallationAddress mdxType="GetInstallationAddress" />
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      