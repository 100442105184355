import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`Objective: High uptime and increase the resilience/redundancy`}
        <ul parentName="li">
          <li parentName="ul">{`Key results:`}
            <ul parentName="li">
              <li parentName="ul">{`Migrate postgres out of heroku`}</li>
              <li parentName="ul">{`Regular synthetic load testing on production`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Why?`}
            <ul parentName="li">
              <li parentName="ul">{`A lack of reliability will cause churn.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Objective: Meet the compliance needs of 5 companies that we cannot meet today`}
        <ul parentName="li">
          <li parentName="ul">{`Key result: EU Cloud is successfully managed`}</li>
          <li parentName="ul">{`Key result: Progress made on SOC2`}</li>
          <li parentName="ul">{`Why?`}
            <ul parentName="li">
              <li parentName="ul">{`Not having these is blocking customers, who we can often uniquely satisfy.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "roadmap"
    }}>{`Roadmap`}</h2>
    <h4 {...{
      "id": "3-year"
    }}>{`3-year`}</h4>
    <ul>
      <li parentName="ul">{`All infrastructure is managed as code`}</li>
      <li parentName="ul">{`Cloud is global`}</li>
      <li parentName="ul">{`Best in class security and privacy compliance`}</li>
      <li parentName="ul">{`Scale beyond 1 Trillion events / month`}</li>
      <li parentName="ul">{`Support non-k8s Deploys 🤖`}</li>
      <li parentName="ul">{`Painless developer/contributor experience`}</li>
    </ul>
    <h4 {...{
      "id": "6-month"
    }}>{`6 month`}</h4>
    <ul>
      <li parentName="ul">{`No Heroku`}</li>
      <li parentName="ul">{`5 Billion / month events`}</li>
      <li parentName="ul">{`SOC 2 Ready`}</li>
      <li parentName="ul">{`All infra is managed as code on prod / staging + EU Ready 🎈`}</li>
      <li parentName="ul">{`Rock solid, reliable infrastructure with regular production load testing to inform scaling plans`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      