import GetInstallationAddress from "../../../../contents/docs/self-host/deploy/snippets/get-installation-address";
import DOIPAddress from "../../../../contents/docs/self-host/deploy/snippets/do-ip-address";
import CommandHelmGetRepoSnippet from "../../../../contents/docs/self-host/deploy/snippets/command-helm-get-repo";
import CommandHelmUpgradeSnippet from "../../../../contents/docs/self-host/deploy/snippets/command-helm-upgrade";
import SetupDNS from "../../../../contents/docs/self-host/deploy/snippets/setup-dns";
import ClusterRequirementsSnippet from "../../../../contents/docs/self-host/deploy/snippets/cluster-requirements";
import InstallingSnippet from "../../../../contents/docs/self-host/deploy/snippets/installing";
import UpgradingSnippet from "../../../../contents/docs/self-host/deploy/snippets/upgrading";
import UninstallingSnippet from "../../../../contents/docs/self-host/deploy/snippets/uninstalling";
import InstallClusterIssuer from "../../../../contents/docs/self-host/deploy/snippets/install-cluster-issuer";
import Disclaimer from "../../../../contents/docs/self-host/deploy/snippets/disclaimer";
import NextSteps from "../../../../contents/docs/self-host/deploy/snippets/next-steps";
import * as React from 'react';
export default {
  GetInstallationAddress,
  DOIPAddress,
  CommandHelmGetRepoSnippet,
  CommandHelmUpgradeSnippet,
  SetupDNS,
  ClusterRequirementsSnippet,
  InstallingSnippet,
  UpgradingSnippet,
  UninstallingSnippet,
  InstallClusterIssuer,
  Disclaimer,
  NextSteps,
  React
};