import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In your React Native or Expo project add the `}<inlineCode parentName="p">{`posthog-react-native`}</inlineCode>{` package to your project as well as the required peer dependencies.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# React Native
yarn add posthog-react-native expo-file-system expo-application expo-device expo-localization

# Expo
expo install posthog-react-native expo-file-system expo-application expo-device expo-localization
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      