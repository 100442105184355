import { StaticImage } from 'gatsby-plugin-image';
import Table from "../../../../contents/_includes/example-table.mdx";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
export default {
  StaticImage,
  Table,
  getImage,
  GatsbyImage,
  React
};