import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Now that your deployment is up and running, here are a couple of guides we'd recommend you check out to fully configure your instance.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/self-host/configure/monitoring-with-grafana"
        }}>{`Setting up monitoring with Grafana`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/self-host/configure/environment-variables"
        }}>{`Environment variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/self-host/configure/securing-posthog"
        }}>{`Securing PostHog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/self-host/configure/running-behind-proxy"
        }}>{`Running behind proxy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/self-host/configure/email"
        }}>{`Email configuration`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      