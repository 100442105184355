import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The best place to initialize the client is in your `}<inlineCode parentName="p">{`Application`}</inlineCode>{` subclass.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class SampleApp extends Application {
  private static final String POSTHOG_API_KEY = "<ph_project_api_key>";
  private static final String POSTHOG_HOST = "<ph_instance_address>";

  @Override
  public void onCreate() {
    // Create a PostHog client with the given context, API key and host.
    PostHog posthog = new PostHog.Builder(this, POSTHOG_API_KEY, POSTHOG_HOST)
      .captureApplicationLifecycleEvents() // Record certain application events automatically!
      .recordScreenViews() // Record screen views automatically!
      .build();

    // Set the initialized instance as a globally accessible instance.
    PostHog.setSingletonInstance(posthog);

    // Now anytime you call PostHog.with, the custom instance will be returned.
    PostHog posthog = PostHog.with(this);
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      