import AndOrFilteringImage from "../../../../contents/images/blog/array/1-36-0-and-or-filtering.png";
import UniversalSearchImage from "../../../../contents/images/blog/array/1-36-0-universal-search.png";
import InsightOnMultipleDashboardsImage from "../../../../contents/images/blog/array/1-36-0-insight-on-multiple-dashboards.png";
import DataColorsImage from "../../../../contents/images/blog/array/1-36-0-data-colors.png";
import NewFunnelVizImage from "../../../../contents/images/blog/array/1-36-0-new-funnel-viz.png";
import NewFunnelVizDashboardImage from "../../../../contents/images/blog/array/1-36-0-new-funnel-viz-dashboard.png";
import TimezoneImage from "../../../../contents/images/blog/array/1-36-0-timezone.png";
import PreflightImage from "../../../../contents/images/blog/array/1-36-0-preflight.png";
import MultiPointPersonsModalImage from "../../../../contents/images/blog/array/1-36-0-multi-point-persons-modal.gif";
import * as React from 'react';
export default {
  AndOrFilteringImage,
  UniversalSearchImage,
  InsightOnMultipleDashboardsImage,
  DataColorsImage,
  NewFunnelVizImage,
  NewFunnelVizDashboardImage,
  TimezoneImage,
  PreflightImage,
  MultiPointPersonsModalImage,
  React
};