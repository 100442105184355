import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This allows you to send more context than the default event info that PostHog captures whenever a user does something. In that case, you can send an event with any metadata you may wish to add.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`posthog.capture('[event-name]', {property1: 'value', property2: 'another value'});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      