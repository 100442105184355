// This information comes from GitLab's location_factors file and converted to JSON
// https://gitlab.com/gitlab-com/www-gitlab-com/blob/master/data/location_factors.yml

interface CompensationCalculatorLocation {
    country: string
    area: string
    locationFactor: number
    currency?: string
}

export const locationFactor: CompensationCalculatorLocation[] = [
    {
        country: 'United States',
        area: 'Connecticut',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Maine',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Portland, Maine',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Massachusetts',
        locationFactor: 0.74,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Boston, Massachusetts',
        locationFactor: 0.85,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'New Hampshire',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Providence, Rhode Island',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Vermont',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'New Jersey',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, New York',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'NYC, New York',
        locationFactor: 0.9,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Pennsylvania',
        locationFactor: 0.69,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Pittsburgh, Pennsylvania',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Philadelphia, Pennsylvania',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Indiana',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Indianapolis, Indiana',
        locationFactor: 0.76,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Michigan',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Detroit, Michigan',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Ohio',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Columbus, Ohio',
        locationFactor: 0.69,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Cleveland, Ohio',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Cincinnati, Ohio',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Wisconsin',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Milwaukee, Wisconsin',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Chicago, Illinois',
        locationFactor: 0.8,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Illinois',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Iowa',
        locationFactor: 0.69,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Des Moines, Iowa',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Nebraska',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Lincoln/Omaha, Nebraska',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Kansas',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'North Dakota',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Minnesota',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Minneapolis, Minnesota',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'South Dakota',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Missouri',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Kansas City, Missouri / Kansas',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'St Louis, Missouri',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Delaware',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Washington DC',
        locationFactor: 0.85,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Florida',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Fort Myers, Florida',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Miami/Fort Lauderdale, Florida',
        locationFactor: 0.71,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Jacksonville, Florida',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Orlando/Tampa, Florida',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Georgia',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Atlanta, Georgia',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Maryland',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, North Carolina',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Charlotte, North Carolina',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Raleigh, North Carolina',
        locationFactor: 0.74,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'South Carolina',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Virginia',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Richmond, Virginia',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Virginia Beach, Virginia',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'West Virginia',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Alabama',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Birmingham, Alabama',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Kentucky',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Louisville/Lexington, Kentucky',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Mississippi',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Jackson, Mississippi',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Tennessee',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Nashville, Tennessee',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Arkansas',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Fayetteville, Arkansas',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Louisiana',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'New Orleans, Louisiana',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Oklahoma',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Texas',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Dallas, Texas',
        locationFactor: 0.8,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Houston, Texas',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Austin, Texas',
        locationFactor: 0.8,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'San Antonio, Texas',
        locationFactor: 0.74,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Arizona',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Phoenix, Arizona',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Tucson, Arizona',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Colorado',
        locationFactor: 0.69,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Denver, Colorado',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Idaho',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Boise, Idaho',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'New Mexico',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Montana',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Salt Lake City, Utah',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Utah',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Nevada',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Las Vegas, Nevada',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Reno, Nevada',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Wyoming',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Jackson, Wyoming',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Cheyenne, Wyoming',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Alaska',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, California',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Sacramento, California',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'San Francisco, California',
        locationFactor: 1,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Santa Barbara, California',
        locationFactor: 0.85,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Los Angeles, California',
        locationFactor: 0.85,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Santa Cruz, California',
        locationFactor: 0.85,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'San Diego, California',
        locationFactor: 0.8,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Hawaii',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Oregon',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Portland, Oregon',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Bend, Oregon',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Eugene, Oregon',
        locationFactor: 0.7,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Everywhere else, Washington',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Seattle, Washington',
        locationFactor: 0.9,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Bellingham, Washington',
        locationFactor: 0.75,
        currency: 'USD',
    },
    {
        country: 'United States',
        area: 'Spokane, Washington',
        locationFactor: 0.65,
        currency: 'USD',
    },
    {
        country: 'Canada',
        area: 'Calgary, Alberta',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Edmonton, Alberta',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, Alberta',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Vancouver, British Columbia',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Victoria, British Columbia',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, British Columbia',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, Manitoba',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Winnipeg, Manitoba',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'New Brunswick',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Newfoundland and Labrador',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Northwest Territories',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Nova Scotia',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Nunavut',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Toronto, Ontario',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Ottawa, Ontario',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, Ontario',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Prince Edward Island',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, Quebec',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Montreal, Quebec',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Quebec City, Quebec',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Everywhere else, Saskatchewan',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Regina, Saskatchewan',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Saskatoon, Saskatchewan',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Canada',
        area: 'Yukon',
        locationFactor: 0.6,
        currency: 'CAD',
    },
    {
        country: 'Bermuda',
        area: 'All',
        locationFactor: 0.95,
        currency: 'BMD',
    },
    {
        country: 'Bahamas',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BSD',
    },
    {
        country: 'Dominican Republic',
        area: 'All',
        locationFactor: 0.6,
        currency: 'DOP',
    },
    {
        country: 'Jamaica',
        area: 'All',
        locationFactor: 0.6,
        currency: 'JMD',
    },
    {
        country: 'Puerto Rico',
        area: 'All',
        locationFactor: 0.6,
        currency: 'USD',
    },
    {
        country: 'Cuba',
        area: 'All',
        locationFactor: 0.6,
        currency: 'CUP',
    },
    {
        country: 'Trinidad and Tobago',
        area: 'All',
        locationFactor: 0.6,
        currency: 'TTD',
    },
    {
        country: 'El Salvador',
        area: 'All',
        locationFactor: 0.6,
        currency: 'USD',
    },
    {
        country: 'Guatemala',
        area: 'All',
        locationFactor: 0.6,
        currency: 'GTQ',
    },
    {
        country: 'Mexico',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MXN',
    },
    {
        country: 'Costa Rica',
        area: 'All',
        locationFactor: 0.6,
        currency: 'CRC',
    },
    {
        country: 'Nicaragua',
        area: 'All',
        locationFactor: 0.6,
        currency: 'NIO',
    },
    {
        country: 'Panama',
        area: 'All',
        locationFactor: 0.6,
        currency: 'PAB',
    },
    {
        country: 'Suriname',
        area: 'All',
        locationFactor: 0.6,
        currency: 'SRD',
    },
    {
        country: 'Venezuela',
        area: 'All',
        locationFactor: 0.6,
        currency: 'VEF',
    },
    {
        country: 'Paraguay',
        area: 'All',
        locationFactor: 0.6,
        currency: 'PYG',
    },
    {
        country: 'Colombia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'COP',
    },
    {
        country: 'Ecuador',
        area: 'All',
        locationFactor: 0.6,
        currency: 'USD',
    },
    {
        country: 'Argentina',
        area: 'All',
        locationFactor: 0.6,
        currency: 'ARS',
    },
    {
        country: 'Bolivia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BOB',
    },
    {
        country: 'Chile',
        area: 'All',
        locationFactor: 0.6,
        currency: 'CLP',
    },
    {
        country: 'Peru',
        area: 'All',
        locationFactor: 0.6,
        currency: 'PEN',
    },
    {
        country: 'Uruguay',
        area: 'All',
        locationFactor: 0.6,
        currency: 'UYU',
    },
    {
        country: 'Brazil',
        area: 'Sao Paulo',
        locationFactor: 0.6,
        currency: 'BRL',
    },
    {
        country: 'Brazil',
        area: 'Rio de Janeiro',
        locationFactor: 0.6,
        currency: 'BRL',
    },
    {
        country: 'Brazil',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'BRL',
    },
    {
        country: 'Algeria',
        area: 'All',
        locationFactor: 0.6,
        currency: 'DZD',
    },
    {
        country: 'Egypt',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EGP',
    },
    {
        country: 'Libya',
        area: 'All',
        locationFactor: 0.6,
        currency: 'LYD',
    },
    {
        country: 'Morocco',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MAD',
    },
    {
        country: 'Tunisia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'TND',
    },
    {
        country: 'Uganda',
        area: 'All',
        locationFactor: 0.6,
        currency: 'UGX',
    },
    {
        country: 'Rwanda',
        area: 'All',
        locationFactor: 0.6,
        currency: 'RWF',
    },
    {
        country: 'Zimbabwe',
        area: 'All',
        locationFactor: 0.6,
        currency: 'ZWL',
    },
    {
        country: 'Zambia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'ZMK',
    },
    {
        country: 'Kenya',
        area: 'All',
        locationFactor: 0.6,
        currency: 'KES',
    },
    {
        country: 'Ethiopia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'ETB',
    },
    {
        country: 'Tanzania',
        area: 'All',
        locationFactor: 0.6,
        currency: 'TZS',
    },
    {
        country: 'Namibia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'NAD',
    },
    {
        country: 'Ghana',
        area: 'All',
        locationFactor: 0.6,
        currency: 'GHS',
    },
    {
        country: 'South Africa',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'ZAR',
    },
    {
        country: 'South Africa',
        area: 'Cape Town',
        locationFactor: 0.6,
        currency: 'ZAR',
    },
    {
        country: 'Nigeria',
        area: 'All',
        locationFactor: 0.6,
        currency: 'NGN',
    },
    {
        country: 'Benin',
        area: 'All',
        locationFactor: 0.6,
        currency: 'XOF',
    },
    {
        country: 'Kazakhstan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'KZT',
    },
    {
        country: 'Uzbekistan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'UZS',
    },
    {
        country: 'China',
        area: 'All',
        locationFactor: 0.6,
        currency: 'CNY',
    },
    {
        country: 'Hong Kong',
        area: 'All',
        locationFactor: 0.76,
        currency: 'HKD',
    },
    {
        country: 'South Korea',
        area: 'Seoul',
        locationFactor: 0.6,
        currency: 'KRW',
    },
    {
        country: 'South Korea',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'KRW',
    },
    {
        country: 'Japan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'JPY',
    },
    {
        country: 'Mongolia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MNT',
    },
    {
        country: 'Taiwan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'TWD',
    },
    {
        country: 'Brunei Darussalam',
        area: 'All',
        locationFactor: 0.6,
    },
    {
        country: 'Cambodia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'KHR',
    },
    {
        country: 'Singapore',
        area: 'All',
        locationFactor: 0.75,
        currency: 'SGD',
    },
    {
        country: 'Indonesia',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'IDR',
    },
    {
        country: 'Indonesia',
        area: 'Jakarta',
        locationFactor: 0.6,
        currency: 'IDR',
    },
    {
        country: 'Malaysia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MYR',
    },
    {
        country: 'Philippines',
        area: 'Makati/Manila',
        locationFactor: 0.6,
        currency: 'PHP',
    },
    {
        country: 'Philippines',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'PHP',
    },
    {
        country: 'Thailand',
        area: 'Bangkok',
        locationFactor: 0.6,
        currency: 'THB',
    },
    {
        country: 'Thailand',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'THB',
    },
    {
        country: 'Vietnam',
        area: 'All',
        locationFactor: 0.6,
        currency: 'VND',
    },
    {
        country: 'Nepal',
        area: 'All',
        locationFactor: 0.6,
        currency: 'NPR',
    },
    {
        country: 'Bangladesh',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BDT',
    },
    {
        country: 'Iran',
        area: 'All',
        locationFactor: 0.6,
        currency: 'IRR',
    },
    {
        country: 'Maldives',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MVR',
    },
    {
        country: 'Pakistan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'PKR',
    },
    {
        country: 'Sri Lanka',
        area: 'All',
        locationFactor: 0.6,
        currency: 'LKR',
    },
    {
        country: 'India',
        area: 'Mumbai',
        locationFactor: 0.6,
        currency: 'INR',
    },
    {
        country: 'India',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'INR',
    },
    {
        country: 'Armenia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'AMD',
    },
    {
        country: 'Georgia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'GEL',
    },
    {
        country: 'Azerbaijan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'AZN',
    },
    {
        country: 'Cyprus',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Jordan',
        area: 'All',
        locationFactor: 0.6,
        currency: 'JOD',
    },
    {
        country: 'Saudi Arabia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'SAR',
    },
    {
        country: 'Turkey',
        area: 'All',
        locationFactor: 0.6,
        currency: 'TRY',
    },
    {
        country: 'Iraq',
        area: 'All',
        locationFactor: 0.6,
        currency: 'IQD',
    },
    {
        country: 'Lebanon',
        area: 'All',
        locationFactor: 0.6,
        currency: 'LBP',
    },
    {
        country: 'Israel',
        area: 'All',
        locationFactor: 0.63,
        currency: 'ILS',
    },
    {
        country: 'Oman',
        area: 'All',
        locationFactor: 0.6,
        currency: 'OMR',
    },
    {
        country: 'Bahrain',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BHD',
    },
    {
        country: 'Qatar',
        area: 'All',
        locationFactor: 0.6,
        currency: 'QAR',
    },
    {
        country: 'United Arab Emirates',
        area: 'Everywhere else',
        locationFactor: 0.62,
        currency: 'AED',
    },
    {
        country: 'United Arab Emirates',
        area: 'Dubai',
        locationFactor: 0.7,
        currency: 'AED',
    },
    {
        country: 'Bulgaria',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BGN',
    },
    {
        country: 'Moldova',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MDL',
    },
    {
        country: 'Romania',
        area: 'All',
        locationFactor: 0.6,
        currency: 'RON',
    },
    {
        country: 'Russia',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'RUB',
    },
    {
        country: 'Russia',
        area: 'Moscow',
        locationFactor: 0.6,
        currency: 'RUB',
    },
    {
        country: 'Belarus',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BYR',
    },
    {
        country: 'Ukraine',
        area: 'All',
        locationFactor: 0.6,
        currency: 'UAH',
    },
    {
        country: 'Slovakia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Hungary',
        area: 'All',
        locationFactor: 0.6,
        currency: 'HUF',
    },
    {
        country: 'Poland',
        area: 'All',
        locationFactor: 0.6,
        currency: 'PLN',
    },
    {
        country: 'Czech Republic',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'CZK',
    },
    {
        country: 'Czech Republic',
        area: 'Prague',
        locationFactor: 0.6,
        currency: 'CZK',
    },
    {
        country: 'Denmark',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'DKK',
    },
    {
        country: 'Denmark',
        area: 'Copenhagen',
        locationFactor: 0.65,
        currency: 'DKK',
    },
    {
        country: 'Finland',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Finland',
        area: 'Helsinki',
        locationFactor: 0.65,
        currency: 'EUR',
    },
    {
        country: 'Iceland',
        area: 'All',
        locationFactor: 0.61,
        currency: 'ISK',
    },
    {
        country: 'Ireland',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Ireland',
        area: 'Dublin',
        locationFactor: 0.7,
        currency: 'EUR',
    },
    {
        country: 'Norway',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'NOK',
    },
    {
        country: 'Norway',
        area: 'Oslo',
        locationFactor: 0.65,
        currency: 'NOK',
    },
    {
        country: 'Sweden',
        area: 'Stockholm',
        locationFactor: 0.6,
        currency: 'SEK',
    },
    {
        country: 'Sweden',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'SEK',
    },
    {
        country: 'Latvia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Lithuania',
        area: 'All',
        locationFactor: 0.6,
        currency: 'LTL',
    },
    {
        country: 'Estonia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'United Kingdom',
        area: 'Northern Ireland',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Wales',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'London, England',
        locationFactor: 0.67,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Bristol, England',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Birmingham, England',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Leeds, England',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Manchester/Liverpool, England',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Everywhere else, England',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Glasgow / Edinburgh, Scotland',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'United Kingdom',
        area: 'Everywhere else, Scotland',
        locationFactor: 0.6,
        currency: 'GBP',
    },
    {
        country: 'Albania',
        area: 'All',
        locationFactor: 0.6,
        currency: 'ALL',
    },
    {
        country: 'Bosnia and Herzegovina',
        area: 'All',
        locationFactor: 0.6,
        currency: 'BAM',
    },
    {
        country: 'Croatia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'HRK',
    },
    {
        country: 'Greece',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Greece',
        area: 'Athens',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Rome',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Milan',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Turin',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Genoa',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Italy',
        area: 'Bologna/Florence',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Malta',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Montenegro',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Portugal',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Portugal',
        area: 'Lisbon',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Serbia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'RSD',
    },
    {
        country: 'Slovenia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Spain',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Spain',
        area: 'Palma De Mallorca',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Spain',
        area: 'Madrid',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Spain',
        area: 'Barcelona',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Spain',
        area: 'Bilbao',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Macedonia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'MKD',
    },
    {
        country: 'Kosovo',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Austria',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Austria',
        area: 'Vienna',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Belgium',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Belgium',
        area: 'Brussels',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'France',
        area: 'Paris',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'France',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Munich/Nuremberg',
        locationFactor: 0.65,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Ruhrgebiet',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Frankfurt/Stuttgart',
        locationFactor: 0.65,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Cologne/Dusseldorf',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Hamburg/Bremen',
        locationFactor: 0.65,
        currency: 'EUR',
    },
    {
        country: 'Germany',
        area: 'Berlin/Leipzig',
        locationFactor: 0.65,
        currency: 'EUR',
    },
    {
        country: 'Luxembourg',
        area: 'All',
        locationFactor: 0.7,
        currency: 'EUR',
    },
    {
        country: 'Netherlands',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Netherlands',
        area: 'Amsterdam',
        locationFactor: 0.6,
        currency: 'EUR',
    },
    {
        country: 'Switzerland',
        area: 'Everywhere else',
        locationFactor: 0.86,
        currency: 'CHF',
    },
    {
        country: 'Switzerland',
        area: 'Geneva',
        locationFactor: 0.91,
        currency: 'CHF',
    },
    {
        country: 'Switzerland',
        area: 'Zug/Zurich',
        locationFactor: 0.91,
        currency: 'CHF',
    },
    {
        country: 'Australia',
        area: 'Everywhere else, Western Australia',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Perth, Western Australia',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Everywhere else, Queensland',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Brisbane, Queensland',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Northern Territory',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Everywhere else, South Australia',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Adelaide, South Australia',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Everywhere else, New South Wales',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Sydney, New South Wales',
        locationFactor: 0.67,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Everywhere else, Victoria',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Melbourne, Victoria',
        locationFactor: 0.65,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Tasmania',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'Australia',
        area: 'Australian Capital Territory',
        locationFactor: 0.6,
        currency: 'AUD',
    },
    {
        country: 'New Zealand',
        area: 'Everywhere else',
        locationFactor: 0.6,
        currency: 'NZD',
    },
    {
        country: 'New Zealand',
        area: 'Auckland',
        locationFactor: 0.6,
        currency: 'NZD',
    },
    {
        country: 'New Zealand',
        area: 'Wellington',
        locationFactor: 0.6,
        currency: 'NZD',
    },
    {
        country: 'New Caledonia',
        area: 'All',
        locationFactor: 0.6,
        currency: 'XPF',
    },
    {
        country: 'Andorra',
        area: 'All',
        locationFactor: 0.6,
        currency: 'EUR',
    },
]
