import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Capture allows you to capture anything a user does within your system, which you can later use in PostHog to find
patterns in usage, work out which features to improve or where people are giving up.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`capture`}</inlineCode>{` call requires:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`event`}</inlineCode>{` to specify the event name`}
        <ul parentName="li">
          <li parentName="ul">{`We recommend naming events with "`}{`[noun][verb]`}{`", such as `}<inlineCode parentName="li">{`movie played`}</inlineCode>{` or `}<inlineCode parentName="li">{`movie updated`}</inlineCode>{`, in order to easily identify what your events mean later on (we know this from experience).`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Optionally you can submit:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`properties`}</inlineCode>{`, which is an object with any information you'd like to add`}</li>
    </ul>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`posthog.capture('Button B Clicked', {
    color: "blue",
    icon: "new2-final"
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      