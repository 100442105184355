import IOSInstall from "../../../../contents/docs/integrate/client/ios/snippets/install.mdx";
import IOSConfigure from "../../../../contents/docs/integrate/client/ios/snippets/configure.mdx";
import IOSIdentify from "../../../../contents/docs/integrate/client/ios/snippets/identify.mdx";
import IOSCapture from "../../../../contents/docs/integrate/client/ios/snippets/capture.mdx";
import * as React from 'react';
export default {
  IOSInstall,
  IOSConfigure,
  IOSIdentify,
  IOSCapture,
  React
};