import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <details>
      <summary>
    <b>Details</b>
      </summary>
      <br />
      <p><inlineCode parentName="p">{`PersistentVolumes`}</inlineCode>{` can be configured to be expandable. This feature when set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, allows the users to resize the volume by editing the corresponding `}<inlineCode parentName="p">{`PersistentVolumeClaims`}</inlineCode>{` object.`}</p>
      <p>{`This can become useful in case your storage usage grows and you want to resize the disk on-the-fly without having to resync data across PVCs.`}</p>
      <p>{`To verify if your storage class allows volume expansion you can run:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`kubectl get storageclass -o json | jq '.items[].allowVolumeExpansion'
true
`}</code></pre>
      <p>{`In case it returns `}<inlineCode parentName="p">{`false`}</inlineCode>{`, you can enable volume expansion capabilities for your storage class by running:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`DEFAULT_STORAGE_CLASS=$(kubectl get storageclass -o=jsonpath='{.items[?(@.metadata.annotations.storageclass\\.kubernetes\\.io/is-default-class=="true")].metadata.name}')
kubectl patch storageclass "$DEFAULT_STORAGE_CLASS" -p '{"allowVolumeExpansion": true}'
storageclass.storage.k8s.io/gp2 patched
`}</code></pre>
      <blockquote>
        <p parentName="blockquote">{`N.B:`}</p>
        <ul parentName="blockquote">
          <li parentName="ul">{`expanding a persistent volume is a time consuming operation`}</li>
          <li parentName="ul">{`some platforms have a per-volume quota of one modification every 6 hours`}</li>
          <li parentName="ul">{`not all the volume types support this feature. Please take a look at the `}<a parentName="li" {...{
              "href": "https://kubernetes.io/docs/concepts/storage/storage-classes/#allow-volume-expansion"
            }}>{`official docs`}</a>{` for more info`}</li>
        </ul>
      </blockquote>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      