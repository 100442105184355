import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "with-objective-c"
    }}>{`With Objective-C`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`#import <PostHog/PHGPostHog.h>
#import <PostHog/PHGPostHogConfiguration.h>

// on posthog.com
PHGPostHogConfiguration *configuration = [PHGPostHogConfiguration configurationWithApiKey:@"YOUR_API_KEY"];

// self-hosted
PHGPostHogConfiguration *configuration = [PHGPostHogConfiguration configurationWithApiKey:@"YOUR_API_KEY" 
                                                                          host:@"https://app.posthog.com"];

configuration.captureApplicationLifecycleEvents = YES; // Record certain application events automatically!
configuration.recordScreenViews = YES; // Record screen views automatically!

[PHGPostHog setupWithConfiguration:configuration];
`}</code></pre>
    <h3 {...{
      "id": "with-swift"
    }}>{`With Swift`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import PostHog

// \`host\` is optional if you use PostHog Cloud (app.posthog.com)
let configuration = PHGPostHogConfiguration(apiKey: "<ph_project_api_key>", host: "<ph_instance_address>")

configuration.captureApplicationLifecycleEvents = true; // Record certain application events automatically!
configuration.recordScreenViews = true; // Record screen views automatically!

PHGPostHog.setup(with: configuration)
let posthog = PHGPostHog.shared()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      