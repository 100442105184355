import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`PostHog is available through `}<a parentName="p" {...{
        "href": "http://cocoapods.org"
      }}>{`CocoaPods`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/Carthage/Carthage"
      }}>{`Carthage`}</a>{` or you can add it as a Swift Package Manager based dependency.`}</p>
    <h3 {...{
      "id": "cocoapods"
    }}>{`CocoaPods`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby",
        "metastring": "file=Podfile",
        "file": "Podfile"
      }}>{`pod "PostHog", "~> 1.1"
`}</code></pre>
    <h3 {...{
      "id": "carthage"
    }}>{`Carthage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby",
        "metastring": "file=Cartfile",
        "file": "Cartfile"
      }}>{`github "posthog/posthog-ios"
`}</code></pre>
    <h3 {...{
      "id": "swift-package-manager"
    }}>{`Swift Package Manager`}</h3>
    <p>{`Add PostHog as a dependency in your Xcode project "Package Dependencies" and select the project target for your app, as appropriate.`}</p>
    <p>{`For a Swift Package Manager based project, add PostHog as a dependency in your "Package.swift" file's Package dependencies section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift",
        "metastring": "file=Package.swift",
        "file": "Package.swift"
      }}>{`dependencies: [
  .package(url: "https://github.com/PostHog/posthog-ios.git", from: "1.4.4")
],
`}</code></pre>
    <p>{`and then as a dependency for the Package target utilizing PostHog:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`  .target(
    name: "myApp",
    dependencies: [.product(name: "PostHog", package: "posthog-ios")]),
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      