import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import CommandHelmGetRepoSnippet from './command-helm-get-repo';
import CommandHelmUpgradeSnippet from './command-helm-upgrade';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To upgrade the `}<a parentName="p" {...{
        "href": "https://helm.sh/"
      }}>{`Helm`}</a>{` release `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` in the `}<inlineCode parentName="p">{`posthog`}</inlineCode>{` namespace:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Get and update the Helm repo:`}</p>
        <CommandHelmGetRepoSnippet mdxType="CommandHelmGetRepoSnippet" />
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Verify if the operation is going to be a major version upgrade`}</strong>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`helm list -n posthog
helm search repo posthog
`}</code></pre>
      </li>
    </ol>
    <p>{`Compare the numbers of the `}<strong parentName="p">{`Helm chart version`}</strong>{` (in the format `}<inlineCode parentName="p">{`posthog-{major}.{minor}.{patch}`}</inlineCode>{` - for example, `}<inlineCode parentName="p">{`posthog-19.15.1`}</inlineCode>{`) when running the commands above. If the upgrade is for a major version, `}<strong parentName="p">{`check the `}<a parentName="strong" {...{
          "href": "/docs/runbook/upgrade-notes"
        }}>{`upgrade notes`}</a>{` before moving forward`}</strong>{`.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Run the upgrade`}</p>
        <CommandHelmUpgradeSnippet mdxType="CommandHelmUpgradeSnippet" />
      </li>
    </ol>
    <p>{`Check the Helm `}<a parentName="p" {...{
        "href": "https://helm.sh/docs/helm/helm_upgrade/"
      }}>{`documentation`}</a>{` for more info about the `}<inlineCode parentName="p">{`helm upgrade`}</inlineCode>{` command.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      