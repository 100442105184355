import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When you call `}<inlineCode parentName="p">{`posthog.init`}</inlineCode>{` the PostHog JS library begins automatically capturing user events:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`pageviews`}</strong>{`, including the URL`}</li>
      <li parentName="ul"><strong parentName="li">{`autocaptured events`}</strong>{`, such as any click, change of input, or submission associated with `}<inlineCode parentName="li">{`a`}</inlineCode>{`, `}<inlineCode parentName="li">{`button`}</inlineCode>{`, `}<inlineCode parentName="li">{`form`}</inlineCode>{`, `}<inlineCode parentName="li">{`input`}</inlineCode>{`, `}<inlineCode parentName="li">{`select`}</inlineCode>{`, `}<inlineCode parentName="li">{`textarea`}</inlineCode>{`, and `}<inlineCode parentName="li">{`label`}</inlineCode>{` tags`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      