import JSInstall from "../../../../contents/docs/integrate/client/js/snippets/install.mdx";
import JSAutoCapture from "../../../../contents/docs/integrate/client/js/snippets/autocapture.mdx";
import JSCaptureIgnoreElements from "../../../../contents/docs/integrate/client/js/snippets/capture-ignore-elements.mdx";
import JSCapture from "../../../../contents/docs/integrate/client/js/snippets/capture.mdx";
import JSIdentify from "../../../../contents/docs/integrate/client/js/snippets/identify.mdx";
import * as React from 'react';
export default {
  JSInstall,
  JSAutoCapture,
  JSCaptureIgnoreElements,
  JSCapture,
  JSIdentify,
  React
};